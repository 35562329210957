.data-card-title {
  font-family: 'Avenir Next';
  font-size: 16px;
  font-weight: 500;
  color: #7a7a7a;
}

.data-card-subtitle {
  font-family: 'Avenir Next';
  font-size: 15px;
  font-weight: 400;
  color: #9b9b9b;
}

.data-card-value {
  font-family: 'Avenir Next';
  font-size: 30px;
  font-weight: 500;
  color: #01a321;
}
