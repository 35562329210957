.desktop-header-content {
  margin-left: 18%;
  width: 20%;
  float: left;
  font-family: 'Avenir Next';
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.project-page-header {
  font-size: 20px;
  color: #4e4e4e;
  font-family: Brown;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
}

.desktop-body-content {
  margin-bottom: 100;
  margin-left: 41%;
  margin-right: 20%;
}

.mobile-content {
  margin-bottom: 100;
  margin-left: 4%;
  margin-right: 4%;
}

.mobile-header {
  font-size: 20;
  color: #4e4e4e;
  font-family: Brown;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
}

.create-milestone-button,
.create-milestone-button:hover {
  box-shadow: none;
  border: none;
  color: #3785ed;
  font-weight: 500;
  background-color: #fafafa;
  float: right;
}

.create-milestone-button:focus {
  background-color: #fafafa;
}

// Project Header styles
.project-header-no-edit {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  padding-bottom: '10px';
  flex: 1;
}

.project-header-name {
  font-family: 'Avenir Next';
  font-size: 20px;
  color: #232323;
  font-weight: 500;
}

.project-header-dates {
  padding-top: 0;
  text-align: 'left';
  float: 'left';
  font-family: 'Avenir Next';
  font-size: 14px;
  color: #737373;
  font-weight: 400;
}

.project-header-edit-button,
.project-header-edit-button:after {
  box-shadow: none !important;
  border: none !important;
  color: #3785ed !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  clear: both;
  float: right !important;
}

// Project header edit styles
.edit-header {
  font-size: 17px;
  font-weight: 500;
  font-family: 'Avenir Next';
}

.email-input {
  width: 80%;
  font-size: 13px;
  background-color: #ffffff;
  resize: none;
  border-radius: 5px;
  padding: 7px;
  padding-left: 12px;
  font-weight: 500;
  color: rgb(97, 97, 97);
  border: 1px solid #d6d6d6;
  font-family: 'Avenir Next';
  white-space: pre-wrap;
  outline: none;
  margin-top: 10px;
}

.add-user-section {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.project-header-archive-button {
  box-shadow: none !important;
  border: none !important;
  color: #ff1414 !important;
  font-weight: 500 !important;
}

.unarchive-button,
.unarchive-button:hover {
  box-shadow: none;
  border: none;
  color: #3785ed;
  font-weight: 500;
}

.right-button-section {
  padding-top: 10px;
  float: right;
}

.left-button-section {
  padding-top: 10px;
  float: left;
}

.project-edit-cancel-button {
  box-shadow: none !important;
  border: none !important;
  color: #3785ed !important;
  font-weight: 500 !important;
}

.save-button,
.save-button:after {
  box-shadow: none !important;
  border: none !important;
  color: #3785ed !important;
  font-weight: 500 !important;
}

// Project header collaborator card
.avatar-img {
  float: left;
  margin-right: 7px;
  vertical-align: middle;
}

.avatar-initials {
  color: #f56a00;
  background-color: #fde3cf;
  float: left;
  margin-right: 7px;
  vertical-align: middle;
}

.collaborator-name {
  vertical-align: middle;
  font-size: 15px;
  color: #4e4e4e;
  font-family: 'Brown';
  font-weight: 500;
  word-break: break-word;
}

// Project header edit collaborator card
.edit-collaborator-card {
  // display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.edit-collaborator-img {
  height: 27;
  width: 27;
  border-radius: 50%;
  margin-right: 8px;
}

.edit-collaborator-avatar {
  height: 27;
  width: 27;
  color: #f56a00;
  background-color: #fde3cf;
  margin-right: 8px;
  vertical-align: middle;
}

.edit-collaborator-name {
  // display: inline-block;
  font-family: 'Avenir Next';
  float: left;
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  max-width: 100px;
  word-break: break-word;
}

.edit-collaborator-role-section {
  // display: inline-block;
  float: right;
  font-family: 'Avenir Next';
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  margin-left: auto;
}

.edit-invite-url {
  font-family: Avenir Next;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
}

// https://stackoverflow.com/questions/218760/how-do-you-keep-parents-of-floated-elements-from-collapsing
.clearfix::after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
}

.edit-notification-switch-label {
  margin: 15px 0px;
  color: #353535;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Avenir Next';
}

@media (max-width: 1000px) {
  .desktop-header-content {
    margin-left: 5% !important;
    width: 30% !important;
  }

  .desktop-body-content {
    margin-left: 38% !important;
    margin-right: 5% !important;
  }
}

.milestone-group {
  margin-bottom: 25px;
}

.milestone-group .section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
}

.section-chevron {
  height: 15px;
  margin-right: 5px;
}

.milestone-group .section-title {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}