.li-body {
  margin-top: 17px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.li-details {
  width: 100%;
}

.li-header {
  padding-right: 5px;
  padding-left: 5px;
  color: #4000ff;
  margin-bottom: 6px;
  font-size: 14px;
}

.li-body .charge-description {
  flex: 0.6;
  text-align: left;
  padding: 5px;
}

.li-body .charge-quantity {
  flex: 0.1;
  text-align: right;
  padding: 5px;
}

.li-body .charge-rate {
  flex: 0.1;
  text-align: right;
  padding: 5px;
}

.li-body .charge-total {
  flex: 0.1;
  text-align: right;
  padding: 5px;
}

.li-body .line-items {
  margin-bottom: 10px;
}

.line-items .charge {
  padding-right: 5px;
  padding-left: 5px;
  color: #646464;
}


.li-body .line-items-total {
  margin-top: 10px;
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
}