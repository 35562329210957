.li-table {
  background: #ffffff;
  border: 1.02823px solid #dddddd;
  box-sizing: border-box;
  border-radius: 8.22581px;
  padding: 15px;
  width: 100%;
  margin: 10px 0;
}

.table-responsive {
  overflow: auto;
}

.table-design2 {
  padding: 0 !important;
  box-shadow: 0px 4px 4px rgba(208, 208, 208, 0.25);
  margin-top: 0 !important;
  border: 1px solid #f0f0f0 !important;
}

.table-design2 th,
.table-design2 td {
  border-right: 1px solid #e9e9e9;
}

.table-design2 th:last-child,
.table-design2 td:last-child {
  border-right: 0px solid #e9e9e9;
}

.table-design2 tr {
  border-bottom: 1px solid #e9e9e9;
}

.table-design2 tr:last-child {
  border-bottom: 0px solid #e9e9e9;
}

.table {
  width: 100%;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3c3c3c;
  table-layout: auto;
}

.table-head {
  background: #f7f7f8;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 8px;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px 10px;
  align-items: center;
}

.subtotal {
  font-family: Avenir Next;
  margin-left: 5px;
  font-weight: 500;
}

.li-input {
  width: 100%;
  border: 1px solid transparent;

  padding: 8px 10px;

  border-radius: 6px;
  height: auto;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.li-input:focus {
  border: 1px solid #177afa;
}

.li-input:hover {
  border: 1px dashed #c9c9c9;
}

.add-li-button {
  cursor: pointer;
  color: #0065ff;
  font-weight: 500;
}