.app-dialog-header {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-weight: 500;
  font-family: 'Avenir Next';
  margin-top: 10px;
  margin-bottom: 15px;
}

// Aloa Credits styling
.aloa-credits-container {
  margin-top: 10px;
  margin-bottom: 10px;

}

.aloa-credits-amount-applied {
  color: #52c41a;
  margin-left: 25px;
}

.aloa-credits-edit-amount {
  cursor: pointer;
  color: #1890ff;
}

.aloa-credits-input {
  margin-left: 5px;
  width: 100px;
}

.add-payment-div-app-dialog {
  cursor: pointer;
}

.add-payment-div-app-dialog:hover {
  color: #1890ff;
}