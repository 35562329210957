.payment-card {
  max-width: 100%;
  color: #000000;
  // margin-bottom: 1em;
  font-weight: 500;
  padding: 20px;
  margin-bottom: 15px;
  border: 0.5px solid;
  border-color: #e6e6e6;
  border-radius: 5px;
}

.cc-img {
  vertical-align: middle;
  height: 25px;
  margin-right: 7px;
  border-radius: 10%;
}

.bank-img {
  vertical-align: middle;
  height: 25px;
  margin-right: 7px;
  border-radius: 10%;
}

.card-method {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.ach {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.ach-pending-verification {
  vertical-align: middle;
  font-size: 16px;
  color: #aaa;
  float: right;
  margin-right: 10px;
}

.payment-card-delete-icon {
  cursor: pointer;
  float: right;
  font-size: 18px;
  margin-top: 5px;
}

.payment-card-delete-icon:hover {
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #999999;
}
