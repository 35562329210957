.edit-button {
  float: right;
  margin-left: 10px;
  box-shadow: none;
  margin-top: 10px;
}

/** Milestone Header **/
.status-text {
  float: right;
  font-family: "Avenir Next";
  font-size: 18px;
  text-align: right;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.status-bubble {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.status-img {
  height: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.card-title {
  font-family: 'Avenir Next';
  font-size: 22px;
  color: #232323;
  font-weight: 500;
}

.header-top-section {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  flex: 1;
}

.header-middle-section {
  display: inline-block;
  vertical-align: middle;
  flex: 0.5;
  padding-left: 30px;
}

.due-date-wrapper {
  padding-top: 0;
  float: left;
  width: 100%;
}

.due-date-content {
  font-family: 'Avenir Next';
  font-size: 14px;
  color: #737373;
  font-weight: 400;
}

.milestone-amount {
  padding-top: 6px;
  text-align: right;
  float: right;
  font-family: 'Avenir Next';
  font-size: 16px;
  color: #323232;
  width: 100%;
  font-weight: 400;
}

/** Milestone Description **/
.milestone-description {
  padding-top: 20px;
  color: #353535;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Avenir Next';
}

.rejection-reason {
  border: 1px solid #ffd7d7;
  padding: 15px 15px 15px 15px;
  border-radius: 5px;
  font-family: 'Avenir Next';
  background-color: #ffe6e6;
  font-weight: 200;
  color: #ff8f8f;
  margin-top: 15px;
  margin-bottom: 15px;
}

/** Milestone buttons **/
.pdf-buttons {
  box-shadow: none;
  border: none;
  color: #3785ed;
  font-weight: 500;
  padding: 0 12.5px;
  margin: 0 2.5px;
}

.pdf-buttons:hover {
  box-shadow: none;
}

/** Milestone Edit **/
.edit-label {
  font-size: 17px;
  font-weight: 500;
  font-family: 'Avenir Next';
  margin-top: 20px;
}

.edit-input {
  margin-top: 10px;
}

.delete-button {
  box-shadow: none;
  border: none;
  color: #ff1414;
  font-weight: 500;
}

.delete-button:hover {
  border-color: #ff1414;
  box-shadow: none;
  color: #ff1414;
}

.cancel-button {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.milestone-save-button {
  margin-bottom: 5;
  margin-top: 10px;
  color: #ffffff !important;
}

.secondary-amount {
  color: #a2a2a2;
  margin-left: 3px;
}

.description-text-error {
  color: #1165ff;
  font-size: 15px;
  font-weight: 500;
  font-family: "Avenir Next";
  margin-top: 5px;
  background: #d7e5ff;
  padding: 10px;
  border-radius: 14px;
}